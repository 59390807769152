import Typography from "@atoms/typography/Typography";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { TestimonialProps } from "../TestimonialsSection.types";

interface TestimonialPropsExtended extends TestimonialProps {
  activeIndex: number;
  index: number;
}

export const Testimonial: FC<TestimonialPropsExtended> = ({
  text,
  authorName,
  authorRole,
  activeIndex,
  index,
}) => {
  const rootStyles = cn(
    "flex select-none flex-col gap-6 transition-colors duration-500 md:gap-7 lg:gap-8 xl:gap-9 2xl:gap-10",
    activeIndex === index
      ? "text-content-primary-on-light"
      : "text-grey-scale-light-gray"
  );

  const roleLineStyles = cn(
    "transition-colors duration-500",
    activeIndex === index ? "text-green-mid-tier" : "text-grey-scale-light-gray"
  );

  return (
    <div className={rootStyles}>
      <Typography
        tag="span"
        variant="base"
        wysiwygStyling
        weight="normal"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <div className="flex flex-col">
        <Typography tag="span" variant="base" className="font-semibold">
          {authorName}
        </Typography>
        <Typography tag="span" variant="xs" className={roleLineStyles}>
          {authorRole}
        </Typography>
      </div>
    </div>
  );
};
