/**
 * @example
 * const urlWithQuery = "https://example.com/some/path/to/resource?param=value";
 * const urlWithoutQuery = "/another/path/to/resource";
 *
 * console.log(extractLastPath(urlWithQuery)); // Output: resource
 * console.log(extractLastPath(urlWithoutQuery)); // Output: resource
 */
export const extractLastSegment = (url?: string) => {
  if (!url) return undefined;
  const withoutQuery = url.split("?")[0];
  const segments = withoutQuery.split("/").filter((segment) => segment !== "");
  return segments.length ? segments[segments.length - 1] : undefined;
};

export const withoutTrailingSlash = (str: string) =>
  str.endsWith("/") ? str.slice(0, -1) : str;

export const removeLeadingAndTrailingSlashes = (s: string | undefined) =>
  s?.replace(/^\/|\/$/g, "");

/**
 * It joins the given array of paths it joins them: "/path1/path2/path3/".
 * Returned path includes slashes at the beginning and at the end
 * @param paths
 */
export const joinPaths = (paths: (string | undefined)[]) => {
  let result = "/";
  for (let i = 0; i < paths.length; i++) {
    const cur = removeLeadingAndTrailingSlashes(paths[i]);
    if (cur) {
      if (result !== "/") result += "/";
      result += cur;
    }
  }
  return result === "/" ? result : result + "/";
};
