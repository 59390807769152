"use client";

import Icon from "@atoms/icon/Icon";
import { cn, tw } from "@utils/tailwind";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { convertBreadcrumb } from "./Breadcrumbs.helpers";
import { Breadcrumb, BreadcrumbsProps } from "./Breadcrumbs.types";

const Breadcrumbs = ({
  rootLabel = "Home",
  skipRootLabel = false,
  upperCaseLabels = true,
  replaceCharacterList = [{ from: "-", to: " " }],
  className,
  theme,
}: BreadcrumbsProps) => {
  const pathname = usePathname();
  const [breadcrumbs, setBreadcrumbs] = useState<Array<Breadcrumb> | null>(
    null
  );

  useEffect(() => {
    if (pathname) {
      const queryInd = pathname.indexOf("?");
      const path = queryInd === -1 ? pathname : pathname.substring(0, queryInd);
      const linkPath = path.split("/");
      linkPath.shift();

      const pathArray = linkPath
        .filter((it) => it && !it.startsWith("#"))
        .map((path, i) => ({
          breadcrumb: path,
          href: "/" + linkPath.slice(0, i + 1).join("/"),
        }));

      setBreadcrumbs(pathArray);
    }
  }, [pathname]);

  if (!breadcrumbs) {
    return null;
  }

  const listElementStyles = tw`flex items-center gap-x-2 text-nowrap`;
  const iconStyles = tw`min-w-1 fill-current`;
  const lastLinkStyles = tw`w-full min-w-0 flex-1 truncate`;

  const navStyles = cn(
    "min-w-0 text-sm font-medium",
    !theme && "text-grey-scale-mid darkGreen:text-blue-light",
    theme === "Light" && "text-grey-scale-mid",
    theme === "Dark" && "text-blue-light",
    className
  );

  return (
    <nav className={navStyles} aria-label="breadcrumbs">
      <ul className="relative inline-flex max-w-full list-none items-center justify-center gap-x-2 py-1">
        {!skipRootLabel && (
          <li className={listElementStyles}>
            <Link
              className={cn(
                "no-underline",
                breadcrumbs.length === 0 && lastLinkStyles
              )}
              href="/"
            >
              {convertBreadcrumb(
                rootLabel || "Home",
                upperCaseLabels,
                replaceCharacterList
              )}
            </Link>
            {breadcrumbs.length > 0 && (
              <Icon className={iconStyles} name="Circle" />
            )}
          </li>
        )}
        {breadcrumbs.length >= 1 &&
          breadcrumbs.map((breadcrumb, i) => {
            const isLastElement = breadcrumbs.length === i + 1;
            return (
              <li
                className={cn(
                  listElementStyles,
                  isLastElement && "overflow-hidden"
                )}
                key={breadcrumb.href}
              >
                <Link
                  className={cn(
                    "no-underline",
                    isLastElement && lastLinkStyles
                  )}
                  href={breadcrumb.href}
                >
                  {convertBreadcrumb(
                    breadcrumb.breadcrumb,
                    upperCaseLabels,
                    replaceCharacterList
                  )}
                </Link>
                {i != breadcrumbs.length - 1 && (
                  <Icon className={iconStyles} name="Circle" />
                )}
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
