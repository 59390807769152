"use client";

import { TagLink } from "@atoms/tag-link/TagLink";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { GreenRoundedTile } from "@molecules/green-rounded-tile/GreenRoundedTile";
import { SectionHeader } from "@molecules/section-header/SectionHeader";
import { cn } from "@utils/tailwind";
import {
  extractLastSegment,
  withoutTrailingSlash,
} from "@utils/urls/url-utils";
import { usePathname } from "next/navigation";
import { FC, useEffect, useState } from "react";
import { PartnerFlexiblySectionProps } from "./PartnerFlexiblySection.types";

export const PartnerFlexiblySection: FC<PartnerFlexiblySectionProps> = ({
  header,
  subheader,
  greenTiles,
  blueTiles,
}) => {
  const pathname = usePathname();
  const [lastSegment, setLastSegment] = useState<string>();

  useEffect(() => {
    if (pathname) setLastSegment(extractLastSegment(pathname));
  }, [pathname]);

  const blueTileStyles = cn(
    "flex flex-col gap-5 rounded-3xl border border-green-accent-two bg-content-primary-on-dark p-5 lg:flex-1 lg:gap-7 lg:p-7 xl:p-10",
    "[&_ul]:flex [&_ul]:list-none [&_ul]:flex-wrap [&_ul]:gap-x-2 [&_ul]:gap-y-2.5 [&_ul]:p-0",
    "[&_ul]:lg:gap-2.5"
  );
  return (
    <ContainerSpacing
      isTransparentBackground
      withBackground
      data-strapi="partner-flexibility"
    >
      <div className="container-flexible">
        <SectionHeader withMarginBottom title={header} subtitle={subheader} />
        {greenTiles && (
          <div className="mb-5 flex flex-col gap-5 xl:flex-row">
            {greenTiles.map((tile, index) => (
              <GreenRoundedTile
                key={`greenTile-${index}`}
                icon={tile.icon}
                title={tile.title}
                text={tile.text}
                urlAddress={`/services/${tile.urlAddress}`}
                className={cn(
                  withoutTrailingSlash(tile.urlAddress) === lastSegment
                    ? "hidden"
                    : "",
                  "grow basis-0"
                )}
              />
            ))}
          </div>
        )}
        {blueTiles && (
          <div className="flex flex-col gap-5 md:flex-row">
            {blueTiles.map((tile, index) => (
              <div className={blueTileStyles} key={`bluetile-${index}`}>
                <h4 className="t-headline-h4">{tile.header}</h4>
                <ul>
                  {tile.tags &&
                    tile.tags.map((tag) => (
                      <li key={tag.children}>
                        <TagLink
                          href={tag.href}
                          className={
                            extractLastSegment(tag.href) === lastSegment
                              ? "hidden"
                              : ""
                          }
                        >
                          {tag.children}
                        </TagLink>
                      </li>
                    ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </ContainerSpacing>
  );
};
