import { cn } from "@utils/tailwind";
import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
} from "react";

export type ContainerSpacingProps = ComponentPropsWithoutRef<"section"> &
  PropsWithChildren<{
    withBackground?: boolean;
    isTransparentBackground?: boolean;
    asDiv?: boolean;
  }>;

const ContainerSpacing = forwardRef(
  (
    {
      children,
      className,
      isTransparentBackground,
      asDiv,
      withBackground = false,
      ...sectionProps
    }: ContainerSpacingProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const styles = cn(
      withBackground && "py-12 md:py-14 lg:py-16 xl:py-20 2xl:py-24",
      !withBackground && "my-12 md:my-14 lg:my-16 xl:my-20 2xl:my-24",
      isTransparentBackground ? "bg-transparent" : "bg-section",
      className
    );

    return asDiv ? (
      <div ref={ref} className={styles} {...sectionProps}>
        {children}
      </div>
    ) : (
      <section ref={ref} className={styles} {...sectionProps}>
        {children}
      </section>
    );
  }
);

ContainerSpacing.displayName = "ContainerSpacing";

export default ContainerSpacing;
