import { ReactNode } from "react";
import { CharacterMap } from "./Breadcrumbs.types";

export const getPathFromUrl = (url: string): string => url.split(/[?#]/)[0];

export const convertBreadcrumb = (
  title: string,
  upperCaseLabels: boolean | undefined,
  replaceCharacterList: Array<CharacterMap> | undefined
): ReactNode => {
  let transformedTitle = getPathFromUrl(title);

  if (replaceCharacterList && replaceCharacterList.length > 0) {
    replaceCharacterList.forEach(
      (_, index) =>
        (transformedTitle = transformedTitle.replaceAll(
          replaceCharacterList[index].from,
          replaceCharacterList[index].to
        ))
    );
  }
  return upperCaseLabels
    ? decodeURI(transformedTitle).toUpperCase()
    : decodeURI(transformedTitle);
};
