"use client";

import { Button } from "@atoms/button/Button";
import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { screens } from "@tailwind/screens";
import { cn } from "@utils/tailwind";
import React, { FC, useRef, useState, useMemo } from "react";
import "swiper/css";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { TestimonialsSectionProps } from "./TestimonialsSection.types";
import { Testimonial } from "./testimonial/Testimonial";

export const TestimonialsSection: FC<TestimonialsSectionProps> = ({
  mainTitle,
  testimonials = [],
}) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNextSlide = () => {
    swiperRef.current?.slideNext();
  };

  const isSingleTestimonial = testimonials.length === 1;
  const slidesPerView = testimonials.length <= 2 ? testimonials.length : 3;

  const adjustedSlides = useMemo(() => {
    if (testimonials.length <= 1) return testimonials;

    const slides = [...testimonials];
    const minSlidesNeeded = slidesPerView + 1;

    while (slides.length < minSlidesNeeded) {
      slides.push(...testimonials);
    }

    const totalSlides =
      Math.ceil(slides.length / slidesPerView) * slidesPerView;
    while (slides.length < totalSlides) {
      slides.push(...testimonials);
    }

    return slides;
  }, [testimonials, slidesPerView]);

  // strechted width for swiper container to avoid changing slidesPerView value on different breakpoints
  const swiperStyles = cn(
    testimonials.length === 2 ? "w-[177%] md:w-[147%]" : "w-[272%] md:w-[227%]",
    "lg:w-[137%] xl:w-[198%] 2xl:w-[140%]"
  );

  return (
    <ContainerSpacing
      isTransparentBackground
      data-strapi="testimonials"
      className="overflow-hidden"
    >
      <div className="container-flexible grid grid-cols-12 gap-y-11 md:gap-y-12 lg:gap-x-5 lg:gap-y-14 xl:gap-5 2xl:gap-10">
        <div className="col-span-10 lg:col-span-10 xl:col-span-3">
          <Typography tag="h2" variant="h2">
            {mainTitle}
          </Typography>
        </div>
        {adjustedSlides.length > 0 && (
          <div className="col-span-12 xl:col-span-8 xl:col-start-5 xl:px-8 2xl:px-5">
            {isSingleTestimonial ? (
              <Testimonial {...testimonials[0]} activeIndex={0} index={0} />
            ) : (
              <>
                <Swiper
                  slidesPerView={slidesPerView}
                  spaceBetween={40}
                  loop
                  speed={500}
                  grabCursor
                  breakpoints={{
                    [screens.md]: { spaceBetween: 100 },
                    [screens.lg]: { spaceBetween: 72 },
                    [screens.xl]: { spaceBetween: 100 },
                  }}
                  onSwiper={(swiper) => (swiperRef.current = swiper)}
                  onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                  className={swiperStyles}
                  onClick={handleNextSlide}
                >
                  {adjustedSlides.map((item, index) => (
                    <SwiperSlide key={index}>
                      <Testimonial
                        {...item}
                        activeIndex={activeIndex}
                        index={index}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <Button
                  className="mt-11 !px-0 md:mt-12 lg:mt-14 xl:mt-16 2xl:mt-20"
                  intent="tertiaryV2"
                  icon="ArrowRight"
                  iconShowOnMobile
                  buttonLabel="See next review"
                  onClick={handleNextSlide}
                />
              </>
            )}
          </div>
        )}
      </div>
    </ContainerSpacing>
  );
};
