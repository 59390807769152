import Typography from "@atoms/typography/Typography";
import { SectionHeaderProps } from "@molecules/section-header/SectionHeader.types";
import { cn, tw } from "@utils/tailwind";
import { FC } from "react";

export const SectionHeader: FC<SectionHeaderProps> = ({
  title,
  subtitle,
  withMarginBottom,
  className,
  titleClassName,
  subtitleClassName,
}) => {
  const gridItemsStyles = tw`lg:col-span-10 xl:col-span-8 2xl:col-span-7`;
  return (
    <div
      className={cn(
        "grid gap-y-4 text-content-primary-on-light darkGreen:text-blue-light md:gap-y-5 lg:grid-cols-12 lg:gap-x-5 xl:gap-y-6 2xl:gap-x-10 2xl:gap-y-7",
        withMarginBottom && "mb-9 md:mb-10 lg:mb-12 xl:mb-14 2xl:mb-16",
        className
      )}
    >
      {typeof title === "string" ? (
        <Typography
          wysiwygStyling
          tag="h2"
          variant="h2"
          weight="medium"
          className={cn(gridItemsStyles, titleClassName)}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      ) : (
        title
      )}
      {typeof subtitle === "string" ? (
        <Typography
          wysiwygStyling
          tag="div"
          variant="base"
          className={cn("empty:hidden", gridItemsStyles, subtitleClassName)}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      ) : (
        subtitle
      )}
    </div>
  );
};
