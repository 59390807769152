import Typography from "@atoms/typography/Typography";
import { GreenRoundedTileProps } from "@molecules/green-rounded-tile/GreenRoundedTile.types";
import { cn } from "@utils/tailwind";
import { FC } from "react";

export const GreenRoundedTile: FC<GreenRoundedTileProps> = ({
  title,
  text,
  urlAddress,
  className,
}) => {
  const rootStyles = cn(
    `group inline-flex flex-col gap-2.5
    rounded-3xl bg-green-dark bg-cover bg-no-repeat p-5
    no-underline transition-all duration-300
    ease-in lg:gap-2 lg:p-8
    xl:gap-9 xl:p-10 xl:hover:bg-green-saturated
    2xl:flex-row 2xl:justify-between 2xl:gap-16`,
    className
  );

  return (
    <a href={urlAddress} className={rootStyles}>
      <div className="flex h-full flex-col flex-wrap content-between">
        <Typography
          tag="h2"
          variant="h3"
          className="mb-4 text-content-primary-on-dark"
        >
          {title}
        </Typography>
        {text && (
          <Typography tag="div" variant="base" className="text-blue-mid">
            {text}
          </Typography>
        )}
      </div>
    </a>
  );
};
