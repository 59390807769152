import { cn } from "@utils/tailwind";
import Link from "next/link";
import { FC } from "react";
import { TagLinkProps } from "./TagLink.types";

export const TagLink: FC<TagLinkProps> = ({
  children,
  href,
  className,
  alternative = false,
}) => {
  const alternativeStyles = alternative
    ? "text-blue-light border-green-dark bg-green-dark xl:hover:border-green-saturated xl:hover:bg-green-saturated"
    : "text-content-primary-on-light border-content-primary-on-light bg-white xl:hover:bg-content-primary-on-light";
  return (
    <Link
      href={href || ""}
      className={cn(
        "t-body-b3 inline-flex items-center justify-center rounded-3xl border border-solid px-2.5 py-1 font-medium no-underline transition duration-300 ease-in-out lg:px-4 lg:pb-1 lg:pt-[5px] xl:hover:text-blue-light 2xl:px-7 2xl:pb-[7px] 2xl:pt-2 ",
        alternativeStyles,
        className
      )}
    >
      {children}
    </Link>
  );
};
